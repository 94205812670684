<template>
  <BaseViewWrapper>
    <BaseHeader>
      <BaseBackButton label="To Personnel" />
      
      <BasePageTitle>
        Creating Staff
      </BasePageTitle>
    </BaseHeader>

    <BaseCard extend-wrapper-classes="border-t-16 border-blue-medium mt-2">
      <form
        @submit="onSubmit"
        class="pt-8 w-full flex flex-wrap pb-10 pl-3 sm:pl-8 lg:pl-2"
      >
        <div class="w-full lg:w-1/2">
          <StaffFieldset
            title="Personal Details"
            :disabled="working"
          >
            <!-- Fields -->
            <VTextInput
              theme="base"
              name="first_name"
              type="text"
              label="First name"
              maxlength="50"
              minlength="2"
            />

            <VTextInput
              theme="base"
              name="last_name"
              type="text"
              label="Last name"
              maxlength="50"
              minlength="2"
            />

            <VTextInput
              theme="base"
              name="dob"
              type="date"
              label="D.O.B."
            />
          </StaffFieldset>

          <StaffFieldset
            title="Contact Details"
            :disabled="working"
          >
            <!-- Fields -->
            <VTextInput
              theme="base"
              name="phone"
              type="text"
              label="Phone number"
              maxlength="15"
              minlength="7"
            />

            <VTextInput
              theme="base"
              name="email"
              type="email"
              label="Email"
              maxlength="100"
            />
          </StaffFieldset>

          <StaffFieldset
            title="Employment Details"
            :disabled="working"
          >
            <div class="w-full flex items-center h-10">
              <label class="md:w-1/3 font-bold md:text-right italic text-gray-900 mr-4 mb-3">
                Rosterable
              </label>
              <div class="md:w-full flex items-center">
                <BaseToggle
                  extend-wrapper-classes="mb-4"
                  :value="isArchitect"
                  :readonly="working"
                  @toggle="toggleIsArchitect"
                />
              </div>
            </div>
          

            <VSelectInput
              v-if="!isArchitect"
              name="sub_grade_id"
              label="Grade"
              theme="base"
              :options="subGradeOptions"
              :loading-options="loadingSubGradeOptions"
            />

            <VSelectInput
              v-if="!isArchitect"
              name="primary_rota_id"
              label="Primary Rota"
              theme="base"
              :options="rotaOptions"
              :loading-options="loadingRotaOptions"
            />
          </StaffFieldset>
        </div>

        <div class="w-full lg:w-1/2">
          <StaffFieldset
            v-if="!isArchitect"
            title="Leave Entitlement Details"
            :disabled="working"
          >
            <VTextInput
              theme="base"
              name="annual_leave"
              type="number"
              label="Annual leave"
              min="0"
            />

            <VTextInput
              theme="base"
              name="study_leave"
              type="number"
              label="Study leave"
              min="0"
            />

            <VTextInput
              theme="base"
              name="parental_leave"
              type="number"
              label="Parental leave"
              min="0"
            />
          </StaffFieldset>
        
          <StaffFieldset
            v-if="!isArchitect"
            title="Contract Details"
            :disabled="working"
          >
            <VSelectInput
              name="contract_id"
              label="Contract Type"
              theme="base"
              :options="contractOptions"
              :loading-options="loadingContractOptions"
            />
          
            <VSelectInput
              v-if="+values.contract_id === 1"
              name="contract_value"
              label="Contract Value"
              theme="base"
              :options="[
                { label: '50', value: 50 },
                { label: '60', value: 60 },
                { label: '75', value: 75 },
                { label: '80', value: 80 },
                { label: '100', value: 100 },
              ]"
            />

            <VTextInput
              v-else-if="+values.contract_id === 2"
              theme="base"
              name="contract_value"
              type="number"
              label="Contract Value"
              min="0.1"
              max="12.0"
              step=".1"
            />
          </StaffFieldset>

          <section class="mt-24 xl:mt-auto w-10/12 sm:w-1/2 lg:w-1/3 ml-auto lg:mr-0 pr-8 xl:pr-24">
            <BaseErrorMessage 
              v-if="submissionError"
              extend-wrapper-classes="mb-3 md:mb-6"
            >
              {{ submissionError }}
            </BaseErrorMessage>

            <!-- Submit -->
            <BaseButton
              theme="auth"
              size="full"
              type="submit"
              :working="working"
            >
              Create
            </BaseButton>
          </section>
        </div>
      </form>
    </BaseCard>
  </BaseViewWrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import UsersAPI from '@/apis/rota-architect/users'
import { userCreateSchema } from '@/schemas/user'

// Form
import { useForm } from 'vee-validate'

// Components
import StaffFieldset from '@/components/staff/layouts/StaffFieldset.vue'

// Hooks
import { useRouter } from 'vue-router'
import useToasts from '@/hooks/useToasts'
import useSelectOptions from '@/hooks/useSelectOptions'

// Utils
import parseErrorMap from '@/utils/parseErrorMap'

export default defineComponent({
  components: {
    StaffFieldset,
  },

  setup () {
    const router = useRouter()
    const dispatch = useToasts()

    // Form rendering
    const isArchitect = ref(false)

    const toggleIsArchitect = () => {
      isArchitect.value = !isArchitect.value
    }

    // Form setup
    const working = ref(false)
    const submissionError = ref('')

    const { handleSubmit, values } = useForm({
      validationSchema:  userCreateSchema,
    })

    const onSubmit = handleSubmit((values) => {
      if (isArchitect.value) {
        values.sub_grade_id = null
        values.rotas = []
      } else {
        values.rotas = [+values.primary_rota_id]
      }

      submissionError.value = ''
      working.value = true

      UsersAPI.create(values)
        .then((res) => {
          router.push({ name: 'view-staff', params: { userId: res.data.id }})
          dispatch.successToast('Staff created successfully.')
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data) || 'Bad request'
        })
        .finally(() => {
          working.value = false
        })
    });


    // Select input options
    const { 
      options: subGradeOptions, 
      loading: loadingSubGradeOptions,
    } = useSelectOptions('SubGrade')

    const { 
      options: siteOptions, 
      loading: loadingSiteOptions,
    } = useSelectOptions('Site')

    const { 
      options: rotaOptions, 
      loading: loadingRotaOptions,
    } = useSelectOptions('Rota')

    const { 
      options: contractOptions, 
      loading: loadingContractOptions,
    } = useSelectOptions('Contract')

    return {
      values,
      onSubmit,
      isArchitect,
      toggleIsArchitect,
      submissionError,
      working,
      subGradeOptions,
      loadingSubGradeOptions,
      siteOptions,
      loadingSiteOptions,
      rotaOptions,
      loadingRotaOptions,
      contractOptions,
      loadingContractOptions,
    }
  },
})
</script>
