
import { defineComponent, ref } from 'vue'
import UsersAPI from '@/apis/rota-architect/users'
import { userCreateSchema } from '@/schemas/user'

// Form
import { useForm } from 'vee-validate'

// Components
import StaffFieldset from '@/components/staff/layouts/StaffFieldset.vue'

// Hooks
import { useRouter } from 'vue-router'
import useToasts from '@/hooks/useToasts'
import useSelectOptions from '@/hooks/useSelectOptions'

// Utils
import parseErrorMap from '@/utils/parseErrorMap'

export default defineComponent({
  components: {
    StaffFieldset,
  },

  setup () {
    const router = useRouter()
    const dispatch = useToasts()

    // Form rendering
    const isArchitect = ref(false)

    const toggleIsArchitect = () => {
      isArchitect.value = !isArchitect.value
    }

    // Form setup
    const working = ref(false)
    const submissionError = ref('')

    const { handleSubmit, values } = useForm({
      validationSchema:  userCreateSchema,
    })

    const onSubmit = handleSubmit((values) => {
      if (isArchitect.value) {
        values.sub_grade_id = null
        values.rotas = []
      } else {
        values.rotas = [+values.primary_rota_id]
      }

      submissionError.value = ''
      working.value = true

      UsersAPI.create(values)
        .then((res) => {
          router.push({ name: 'view-staff', params: { userId: res.data.id }})
          dispatch.successToast('Staff created successfully.')
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data) || 'Bad request'
        })
        .finally(() => {
          working.value = false
        })
    });


    // Select input options
    const { 
      options: subGradeOptions, 
      loading: loadingSubGradeOptions,
    } = useSelectOptions('SubGrade')

    const { 
      options: siteOptions, 
      loading: loadingSiteOptions,
    } = useSelectOptions('Site')

    const { 
      options: rotaOptions, 
      loading: loadingRotaOptions,
    } = useSelectOptions('Rota')

    const { 
      options: contractOptions, 
      loading: loadingContractOptions,
    } = useSelectOptions('Contract')

    return {
      values,
      onSubmit,
      isArchitect,
      toggleIsArchitect,
      submissionError,
      working,
      subGradeOptions,
      loadingSubGradeOptions,
      siteOptions,
      loadingSiteOptions,
      rotaOptions,
      loadingRotaOptions,
      contractOptions,
      loadingContractOptions,
    }
  },
})
